import React, { useState, useContext, useEffect } from 'react';
import { StateContext } from '../../../StateProvider';
import { useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { auth, signInWithSMS, verifyCode, RecaptchaVerifier } from '../../../Login/Firebase';
import Popup from '../../../Utilities/components/Popup';
import CardDisplay from '../../../Utilities/components/CardDisplay/CardDisplay';
import ApplePayDisplay from '../../../Utilities/components/ApplePayDisplay';
import { processStripePayment } from '../../../Utilities/api';
import sanitizePhone from '../../../Utilities/functions/SanitizePhone';
import formatPhoneForDisplay from '../../../Utilities/functions/FormatPhoneForDisplay';
import './Checkout.css';

import sanitizeDate from '../../../Utilities/functions/SanitizeDate';

import { WhiteBackButton, PaymentMethodViewButton } from '../../../Utilities/components/Buttons';
import addCardIcon from '../../../../assets/Dashboard/Profile/components/Wallet/addCardIcon.png';

const Checkout = () => {
    const navigate = useNavigate();
    const elements = useElements();
    const { user, cart, cartTotal, paymentMethods, setLoading, refreshUserData } = useContext(StateContext);

    // States for user contact details
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);

    // States for other checkout logic
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [defaultMethod, setDefaultMethod] = useState(null);
    const [stripeCustomerId, setStripeCustomerId] = useState('');

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const [confirmation, setConfirmation] = useState(null);
    const [confirmationSent, setConfirmationSent] = useState(true);
    const [smsNotifications, setSmsNotifications] = useState(true);
    const [emailNotifications, setEmailNotifications] = useState(true);
    const [emailUpdate, setEmailUpdate] = useState(true);
    const [phoneUpdate, setPhoneUpdate] = useState(true);
    const [newEmail, setNewEmail] = useState('');
    const [newPhone, setNewPhone] = useState('');

    // Effect to set initial contact details
    useEffect(() => {
        if (user && user.info) {
            setEmail(user.info.email || '');
            setPhoneNumber(formatPhoneForDisplay(user.info.phone) || '');
        }
        if (paymentMethods && user) {
            const defaultPaymentMethod = paymentMethods.find(method => String(method.id) === user.defaultPaymentMethodId);
            setDefaultMethod(defaultPaymentMethod || paymentMethods[0]);
            setStripeCustomerId(user.stripeCustomerId);
        }
    }, [user, paymentMethods]);

    const confirmPopupChange = (type) => {
        setConfirmation(type);
        if (type === 'email' || type === 'sms') {
            togglePopup('confirm');
        } else if (type === 'emailUpdate') {
            togglePopup('emailUpdate');
        } else if (type === 'phoneUpdate') {
            togglePopup('phoneUpdate');
        } else if (type === 'confirmationSent') {
            togglePopup('confirmationSent');
        } else if (type === 'agreedToTermsRequired') {
            togglePopup('agreedToTermsRequired');
        } else if (type === 'emailRequired') {
            togglePopup('emailRequired');
        } else if (type === 'phoneNumberRequired') {
            togglePopup('phoneNumberRequired');
        }
    };

    const togglePopup = (content = '') => {
        setPopupContent(content);
        setIsPopupOpen(!isPopupOpen);
    };

    const handleSubmit = async (e) => {  
        e.preventDefault();
        setLoading(true);
        
        if (!agreedToTerms || !agreedToPrivacy) {
            confirmPopupChange('agreedToTermsRequired');
            setLoading(false);
            return;
        }
    
        if (!email) {
            confirmPopupChange('emailRequired');
            setLoading(false);
            return;
        }
    
        if (!phoneNumber) {
            confirmPopupChange('phoneNumberRequired');
            setLoading(false);
            return;
        }
    
        setProcessing(true);
    
        try {
            const response = await processStripePayment(
                stripeCustomerId, 
                defaultMethod.stripePaymentMethodId, 
                cart, 
                cartTotal, 
                'usd', 
                'Payment for services', 
                'automatic'
            );
    
            if (response && response.status === 'succeeded') {
                refreshUserData(user.uid);
                navigate('/events');
                setLoading(false);
            } else {
                console.error('Payment failed:', response);
                alert('Payment failed! Please try again.');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('There was an error processing your payment. Please try again.');
            setLoading(false);
        } finally {
            setProcessing(false);
            setLoading(false);
        }
    };    
    
    const handleEmailChange = () => {
    // If emailNotifications is true, we want to confirm turning them off
    if (emailUpdate) {
        confirmPopupChange('emailUpdate');
    } else {
        setEmailUpdate(true);
    }
    };

    const handlePhoneChange = () => {
    // If emailNotifications is true, we want to confirm turning them off
    if (phoneUpdate) {
        confirmPopupChange('phoneUpdate');
    } else {
        setPhoneUpdate(true);
    }
    };

    const handleConfirm = () => {
    if (confirmation === 'email') {
        setEmailNotifications(false);
    } else if (confirmation === 'sms') {
        setSmsNotifications(false);
    }
    closePopup();
        setConfirmation(null);
    };
    
    const handleCancel = () => {
        closePopup();
        setConfirmation(null);
    };
    
    const closePopup = () => {
        setIsPopupOpen(false);
        setPopupContent('');
    };  

    const handleUpdateEmail = () => {
        setEmail(newEmail);
        setPopupContent('confirmationSent');
        setConfirmationSent(true);
    };
    
    const handleUpdatePhone = () => {
        setPhoneNumber(formatPhoneForDisplay(newPhone));
        setPopupContent('confirmationSent');
        setConfirmationSent(true);
    };  

    const handlePhoneUpdate = async () => {
        if (newPhone) {
            const result = await signInWithSMS(newPhone, 'recaptcha-container');
            if (result.success) {
                setConfirmationResult(result.confirmationResult);
                setPopupContent('confirmationSent');
                setIsPopupOpen(true);
            } else {
                console.error(result.error);
            }
        }
    };

    const handleVerifyCode = async () => {
        if (verificationCode && confirmationResult) {
            const result = await verifyCode(verificationCode, confirmationResult);
            if (result.success) {
                console.log('Phone number verified successfully!');
                // Proceed with your logic after successful verification
            } else {
                console.error('Verification failed:', result.error);
            }
        }
    };

    const displayDateRange = (dateRange) => {
        const sanitizedDateRange = sanitizeDate(dateRange).split(' - ');
        if (sanitizedDateRange.length !== 2) {
            return "Invalid Date Range";
        }
    
        const [startDate, endDate] = sanitizedDateRange;
        
        if (startDate === endDate) {
            return startDate;
        }
    
        return `${startDate} - ${endDate}`;
    };

    const formatBookingType = (bookingType) => {
        if (bookingType.includes('Membership')) {
          return 'Membership';
        }
        return bookingType;
    };

    // Determine the default payment method or fall back to the first method
    const methodToDisplay = defaultMethod || paymentMethods[0];

    return (
        <div>
            <div className='checkout-backdrop'>
                <div className='container'>
                <WhiteBackButton />
                <div className="checkout-container">
                    <div>
                        {/* Payment Summary */}
                        <div className='checkout-summary-container'>
                            <h2>Payment Summary</h2>
                            <hr className='checkout-breakline'/>
                            <div className='checkout-items-container'>
                                {cart.map((item, index) => (
                                    <div key={index}>
                                        <div key={index} className='checkout-item-summary'>
                                            <div className='checkout-item-description'>
                                                <span className='checkout-item-service'>{formatBookingType(item.bookingType)} - </span> 
                                                <span className='checkout-item-name'>{
                                                    item.bookingInfo.selectedPets.map((pet, index, array) => {
                                                        return `${pet.petName}${index < array.length - 1 ? ', ' : ''}`;
                                                    }).join('')
                                                } </span>
                                                <span className='checkout-item-date'>({displayDateRange(item.bookingInfo.dateRange)})</span>
                                            </div>
                                            <div className='checkout-item-originalPrice'>
                                                ${item.originalPrice.toFixed(0)}
                                            </div>
                                        </div>
                                        {item.originalPrice !== item.discountedPrice
                                            ?   (
                                                    <div className='checkout-item-discountSummary'>
                                                        <div className='checkout-item-description'>
                                                            <div className='checkout-item-service'>
                                                                Pawsible Members
                                                            </div>
                                                        </div>
                                                        <div className='checkout-item-discountedPrice'>
                                                            -${(item.originalPrice.toFixed(0) - item.discountedPrice.toFixed(0)).toFixed(0)}
                                                        </div>
                                                    </div>
                                                )
                                            :   null
                                        }
                                    </div>
                                ))}
                            </div>

                    <hr className='checkout-breakline'/>

                    {/* <div className='checkout-subtotal-cost'>
                        ${cartOriginalTotal.toFixed(2)}
                    </div>
                    <div className='checkout-total-saving'>
                        <span className='checkout-total-saving-highlight'>-${cartTotalDiscount.toFixed(2)}</span>
                    </div> */}
                    <div className='checkout-total-cost'>
                        <strong>Total</strong> ${cartTotal.toFixed(0)}
                    </div>
                </div>

                <div className='checkout-contact-container'>
                    <h2>Contact Details</h2>
                    <div className="checkout-contact-card">
                        <h3 className='checkout-card-title'>Email</h3>
                        <p className='checkout-card-info' onClick={handleEmailChange}>{email || "Enter Email"}</p>
                        <button className='checkout-card-button' onClick={handleEmailChange}>Edit</button>
                    </div>
                    <hr className='profile-breakline'/>
                    <div className="checkout-contact-card">
                        <h3 className='checkout-card-title'>Phone</h3>
                        <p className='checkout-card-info' onClick={handlePhoneChange}>{phoneNumber || "Enter Phone"}</p>
                        <button className='checkout-card-button' onClick={handlePhoneChange}>Edit</button>
                    </div>
                </div>


                {/* Payment Method Section */}
                <div className='checkout-payment-container'>
                    <div className='checkout-payment-header-container'>
                        <h2>Payment Methods</h2>
                        <PaymentMethodViewButton />
                    </div>

                    {methodToDisplay ? (
                        <div onClick={() => navigate(`/profile/wallet/payment-methods/edit-payment/${methodToDisplay.id}`)}>
                            <CardDisplay key={methodToDisplay.id} method={methodToDisplay} />
                        </div>
                        ) : null 
                    }

                    <hr className='wallet-payment-breakline'/>

                    <ApplePayDisplay />
                    <div className='wallet-card-display'>
                        <img src={addCardIcon} alt={`$wallet-{cardType} logo`} className="wallet-card-logo" />
                        {/* <button className='wallet-payment-add-button'><img src={addCardIcon} className='wallet-payment-add-image'></img></button> */}
                        {/* <div className="wallet-card-number">{cardNumber}</div> */}
                        <div className="wallet-card-info">
                            <div className="wallet-card-number" onClick={() => navigate('/profile/wallet/payment-methods/add-payment')} >Add Card</div>
                        </div>
                    </div>

                </div>

                    </div>

                    <Popup isOpen={isPopupOpen} handleClose={closePopup}>
                        {popupContent === 'confirm' && (
                            <div className='profile-popup'>
                            <h2 className='profile-popup-header'>Unsubscribe?</h2>
                            <p className='profile-popup-message'>Opting out could result in missing important booking information from Pawsible.</p>
                            <div className="profile-popup-actions">
                                <button onClick={handleConfirm} className='profile-popup-yes-button'>YES</button>
                                <button onClick={handleCancel} className='profile-popup-no-button'>NO</button>
                            </div>
                            </div>
                        )}
                        {popupContent === 'emailUpdate' && (
                            <div className='profile-popup'>
                            <h2 className='profile-popup-header'>Update Email</h2>
                            <p className='profile-popup-message'>{email}</p>
                            <input
                                type="email"
                                placeholder="Enter new email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                            />
                            <div className="profile-popup-actions">
                                <button onClick={handleUpdateEmail} className='profile-popup-update-button-yes'>Confirm</button>
                                <button onClick={handleCancel} className='profile-popup-update-button-no'>Cancel</button>
                            </div>
                            </div>
                        )}
                        {popupContent === 'phoneUpdate' && (
                            <div className='profile-popup'>
                            <h2 className='profile-popup-header'>Update Number</h2>
                            <p className='profile-popup-message'>{phoneNumber}</p>
                            <input
                                type="tel"
                                placeholder="Enter new phone number"
                                value={newPhone}
                                onChange={(e) => setNewPhone(e.target.value)}
                            />
                            <div className="profile-popup-actions">
                                <button onClick={handlePhoneUpdate} className='profile-popup-update-button-yes'>Send Code</button>
                                <button onClick={handleCancel} className='profile-popup-update-button-no'>Cancel</button>
                            </div>
                            <div id="recaptcha-container"></div>  {/* Recaptcha container */}
                            </div>
                        )}
                        {popupContent === 'confirmationSent' && (
                            <div className='profile-popup'>
                            <h2 className='profile-popup-header'>Confirmation Sent!</h2>
                            <p className='profile-popup-message'>To finalize the update of your contact details, please <strong>confirm the verification</strong> sent to your Email/Phone.</p>
                            <p className='profile-popup-message'>We appreciate your prompt attention to this matter!</p>
                            <div className="profile-popup-actions-confirmation">
                                <button onClick={handleCancel} className='profile-popup-update-button-ok'>OK</button>
                                {/* <button onClick={handleCancel} className='profile-popup-update-button-no'>Cancel</button> */}
                            </div>
                            </div>
                        )}
                        {(popupContent === 'agreedToTermsRequired') && (
                            <div className='profile-popup'>
                            <h2 className='profile-popup-header'>Missing Information</h2>
                            <p className='profile-popup-message'>To complete the process, please agree to the policies.</p>
                            <div className="profile-popup-actions-confirmation">
                                <button onClick={handleCancel} className='profile-popup-update-button-ok'>OK</button>
                            </div>
                            </div>
                        )}
                        {(popupContent === 'emailRequired') && (
                            <div className='profile-popup'>
                            <h2 className='profile-popup-header'>Missing Information</h2>
                            <p className='profile-popup-message'>To complete the process, please provide your Email.</p>
                            <div className="profile-popup-actions-confirmation">
                                <button onClick={handleCancel} className='profile-popup-update-button-ok'>OK</button>
                            </div>
                            </div>
                        )}
                        {(popupContent === 'phoneNumberRequired') && (
                            <div className='profile-popup'>
                            <h2 className='profile-popup-header'>Missing Information</h2>
                            <p className='profile-popup-message'>To complete the process, please provide your Phone Number.</p>
                            <div className="profile-popup-actions-confirmation">
                                <button onClick={handleCancel} className='profile-popup-update-button-ok'>OK</button>
                            </div>
                            </div>
                        )}
                    </Popup>

                </div>


            </div>
        </div>
            <form onSubmit={handleSubmit} className='checkout-form-container'>
                {/* Policies and Agreements */}
                <section className='checkout-policies-container'>
                    <div className="checkout-terms-and-conditions">
                        <label className='checkout-policies-label'>
                            <input
                                type="checkbox"
                                checked={agreedToTerms}
                                onChange={(e) => setAgreedToTerms(e.target.checked)}
                            /> I agree to the <a href="/legal/terms-and-conditions" target="_blank">Terms & Conditions</a>
                        </label>
                        <label className='checkout-policies-label'>
                            <input
                                type="checkbox"
                                checked={agreedToPrivacy}
                                onChange={(e) => setAgreedToPrivacy(e.target.checked)}
                            /> I agree to the <a href="/legal/terms-and-conditions" target="_blank">Privacy Policy</a>
                        </label>
                    </div>
                </section>

                {/* Confirmation Button */}
                <div className='checkout-confirmation-container'>
                    <button type="submit" disabled={!agreedToTerms || !agreedToPrivacy}>
                        Confirm & Pay
                    </button>
                </div>
            </form>
    </div>
    );
};

export default Checkout;
