import React, { useContext, useState, useEffect } from 'react';
import { StateContext } from '../../../../StateProvider';
import Popup from '../../../../Utilities/components/Popup';
import { redeemReferralCode } from '../../../../Utilities/api';
import './Invite.css';

import inviteBackground from '../../../../../assets/Store/Deals/Invite/inviteBackground.png';
import { XButton } from '../../../../Utilities/components/Buttons';

const Invite = () => {
    const { user, loading, setLoading } = useContext(StateContext);

    // State variables for referrals and referralCode
    const [referrals, setReferrals] = useState(0);
    const [referralCode, setReferralCode] = useState('');
    const [shareLink, setShareLink] = useState('');
    
    // Popup state and handlers
    const [copyButtonText, setCopyButtonText] = useState('Copy');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const [redeemCode, setRedeemCode] = useState('');
    const [message, setMessage] = useState('');
    const milestones = [5, 10, 15];

    // Calculate the progress bar width
    const progress = ((referrals - milestones[0]) / (milestones[milestones.length - 1] - milestones[0])) * 100;

    useEffect(() => {
        if (user) {
            setReferrals(user.referrals || 0);
            setReferralCode(user.referralCode || '');
        }
    }, [user]);

    useEffect(() => {
        if (referralCode) {
            setShareLink(`https://pawsible.com/referral/${referralCode}`);
        }
    }, [referralCode]);

    const togglePopup = (content = '') => {
        setPopupContent(content);
        setIsPopupOpen(!isPopupOpen);
    };

    const handleRedeemCode = () => {
        togglePopup('redeemCode');
    };

    const handleShareLink = () => {
        togglePopup('shareLink');
    };

    const handleRedeem = async () => {
        setLoading(true);
        try {
            const response = await redeemReferralCode(redeemCode);
            if (response.success) {
                setMessage('Code redeemed successfully!');
                // Update the user's referral count or other relevant state
            } else {
                setMessage('Invalid referral code. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
            console.error('Error redeeming code:', error);
        } finally {
            setLoading(false);
            togglePopup();
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(referralCode)
            .then(() => {
                setCopyButtonText('Woof!');
                setTimeout(() => setCopyButtonText('Copy'), 2000); // Reset button text after 2 seconds
            })
            .catch(() => {
                alert('Failed to copy the referral link.');
            });
    };

    const handlePopupShareLink = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Pawsible Referral',
                text: 'Check out Pawsible!',
                url: shareLink,
            })
            .then(() => {
                console.log('Successfully shared');
            })
            .catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            navigator.clipboard.writeText(shareLink)
                .then(() => {
                    console.log('Referral link copied to clipboard!')
                })
                .catch(() => {
                    console.error('Failed to copy the referral link.')
                });
        }
        togglePopup();
    };

    return (
        <div>
            <XButton />
            <img className='invite-hero-image' src={inviteBackground}></img>
            <div className="invite-container">
                <h2 className='invite-header'>Refer & Earn</h2>
                <p className='invite-paragraph'>Share Pawsible with your friends. They get <span className="highlight-invite">$30 Welcome Voucher</span> and you earn rewards when you <strong>reach the milestones!</strong></p>
                
                <div className='reward-container'>
                    <div className="referrals-count">
                    <span className="highlight-referrals">{referrals}</span> REFERRALS
                    </div>

                    <div className="rewards">
                        {milestones.map((milestone, index) => (
                            <div
                                key={index}
                                className={`reward-card ${referrals >= milestone ? 'highlighted' : 'greyed-out'}`}
                            >
                                <div className={`reward-amount ${referrals >= milestone ? 'highlighted' : 'greyed-out'}`}>
                                    {index === 0 ? '$50' : index === 1 ? '$175' : '$425'}
                                </div>
                                <div className={`reward-message ${referrals >= milestone ? 'highlighted' : 'greyed-out'}`}>Pawsible Tokens</div>
                            </div>
                        ))}
                    </div>

                    <div className='reward-progress-bar-container'>
                        <div className='reward-progress-bar' style={{ width: `${Math.max(0, progress)}%` }}></div>
                        {milestones.map((milestone, index) => (
                            <div
                                key={index}
                                className={`milestone ${referrals >= milestone ? 'highlighted' : 'greyed-out'}`}
                                style={{ left: `${((milestone - milestones[0]) / (milestones[milestones.length - 1] - milestones[0])) * 100}%` }}
                            >
                                {milestone}
                            </div>
                        ))}
                    </div>
                </div>
                
                <button className="redeem-btn" onClick={handleRedeemCode}>Redeem Code</button>
                <button className="share-btn" onClick={handleShareLink}>Share Referral Link</button>
                <div className="referral-code">
                    <input type="text" value={referralCode} readOnly />
                    <button className='copy-btn' onClick={handleCopyLink}>{copyButtonText}</button>
                </div>
                
            </div>

            <Popup isOpen={isPopupOpen} handleClose={() => setIsPopupOpen(false)}>
                {popupContent === 'redeemCode' && (
                    <div className='invite-popup'>
                        <h2 className='invite-popup-header'>Redeem a Code</h2>
                        <input
                            type="text"
                            placeholder="Enter your code"
                            value={redeemCode}
                            onChange={(e) => setRedeemCode(e.target.value)}
                        />
                        {message && <p className="invite-popup-message">{message}</p>}
                        <div className="invite-popup-actions">
                            <button onClick={handleRedeem} className='invite-popup-yes-button'>Redeem</button>
                            <button onClick={() => setIsPopupOpen(false)} className='invite-popup-no-button'>Cancel</button>
                        </div>
                    </div>
                )}
                {popupContent === 'shareLink' && (
                    <div className='invite-popup'>
                        <h2 className='invite-popup-header'>Share Referral Link</h2>
                        <p className='invite-popup-message'>Share this link with your friends:</p>
                        <input
                            type="text"
                            value={shareLink}
                            readOnly
                        />
                        <div className="invite-popup-actions">
                            <button onClick={handlePopupShareLink} className='invite-popup-yes-button'>{navigator.share ? 'Share' : 'Copy'}</button>
                            <button onClick={() => setIsPopupOpen(false)} className='invite-popup-no-button'>Cancel</button>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    );
}

export default Invite;
