import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { createStripeCustomer, attachPaymentMethodToCustomer, setDefaultPaymentMethod } from '../../../../../../Utilities/api';
import { WhiteBackButton } from '../../../../../../Utilities/components/Buttons';
import { StateContext } from '../../../../../../StateProvider';
import './AddPaymentMethod.css';
import Footer from '../../../../../../Footer';

const AddPaymentMethod = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { user, refreshUserData, loading, setLoading } = useContext(StateContext);
  const [cardholderName, setCardholderName] = useState('');
  const [error, setError] = useState(null);
  const [isDefault, setIsDefault] = useState(false);

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '12px',
        color: '#424770',
        padding: '15px',
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardholderName,
      },
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    try {
      // Create a Stripe customer if not already created
      let stripeCustomerId = user.stripeCustomerId;
      if (!stripeCustomerId) {
        const customerResponse = await createStripeCustomer(user.uid, user.email);
        stripeCustomerId = customerResponse.stripeCustomerId;
        await refreshUserData(user.uid); // Update user data with new Stripe customer ID
      }
      await attachPaymentMethodToCustomer(stripeCustomerId, paymentMethod.id, user.uid);
      await refreshUserData(user.uid);
      navigate(-1);
    } catch (error) {
      setError('Failed to add payment method');
      console.error('Failed to add payment method', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='add-payment-backdrop'>
        <div className="container">
          <div className='payment-add-button-container'>
            <WhiteBackButton />
          </div>
          <div className="add-payment-method">
            <h2 className='add-payment-header'>Add Payment Method</h2>
            <form>
              <label htmlFor="cardholderName">Cardholder Name</label>
              <input
                type="text"
                id="cardholderName"
                name="cardholderName"
                value={cardholderName}
                onChange={(e) => setCardholderName(e.target.value)}
                placeholder="Enter Here"
              />
            </form>
            <div className='add-payment-stripe-container'>
              <label className='add-payment-stripe-label'>Card Details</label>
              <CardElement className='StripeElement' options={cardElementOptions} />
            </div>
          </div>
        </div>
      </div>
      <div className='add-payment-method-submit-container'>
        <button onClick={handleAddPaymentMethod} className='add-payment-method-submit' disabled={loading}>
          {loading ? 'Processing...' : 'Add'}
        </button>
      </div>
      <Footer />
    </>
  );
};

export default AddPaymentMethod;
