

export const HotelDiscountCalculator = (bookingData, serviceDetails) => {
    const basePriceFirstDog = serviceDetails.hotel ? serviceDetails.hotel[0].price : 80;
    const basePriceForWalk = serviceDetails.walk ? serviceDetails.walk[0].price : 20;
    const petDiscount = 0.10; // 10% discount for each additional dog
    
    let total = 0;  // Starting with a base of 0
    let originalTotal = 0; // Keep track of the price without any discounts
    const numberOfPets = bookingData.selectedPets ? bookingData.selectedPets.length : 0;
    const numberOfNights = calculateNumberOfNights(bookingData.dateRange);

    // Charge for the first dog at full price
    if (numberOfPets >= 1) {
        total += basePriceFirstDog * numberOfNights;
        originalTotal += basePriceFirstDog * numberOfNights;
    }
  
    // Charge for additional dogs with compounded discount
    if (numberOfPets > 1) {
        for (let i = 1; i < numberOfPets; i++) {
            const discountRate = petDiscount * i; // Compounded discount rate
            const discountedPricePerNight = basePriceFirstDog * (1 - discountRate);
            total += discountedPricePerNight * numberOfNights;
            originalTotal += basePriceFirstDog * numberOfNights; // No discount applied to original
        }
    }

    // Food prices adjusted for the number of pets
    if (bookingData.foodPreference === 'premium') {
      total += 20 * numberOfNights * numberOfPets;
      originalTotal += 20 * numberOfNights * numberOfPets;
    } else if (bookingData.foodPreference === 'basic') {
      total += 5 * numberOfNights * numberOfPets;
      originalTotal += 5 * numberOfNights * numberOfPets;
    }
    
    // Add the cost of the "walk" service only if selected
    if (bookingData.additionalServices && bookingData.additionalServices.walk) {
        total += (basePriceForWalk) * numberOfPets; // Multiply by the number of pets
        originalTotal += (basePriceForWalk) * numberOfPets; // Apply to original total as well
    }

    return { originalPrice: originalTotal, discountedPrice: total };
};

// Function to calculate the number of nights based on the date range
function calculateNumberOfNights(dateRange) {
    if (dateRange && dateRange.length === 2) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        const numberOfNights = Math.ceil((endDate - startDate) / (24 * 60 * 60 * 1000));
        return Math.max(numberOfNights, 1);  // Ensure at least 1 night
    }
    return 1;  // Default to 1 night
}

export default HotelDiscountCalculator;
